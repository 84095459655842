/* CompanyBrandLogoAnimated.module.css */

@keyframes iconToCheck {
    0% { opacity: 1; transform: scale(1); }
    25% { opacity: 0; transform: scale(0); }
    50% { opacity: 0; transform: scale(0); }
    75% { opacity: 1; transform: scale(1); }
    100% { opacity: 1; transform: scale(1); }
  }
  
  @keyframes checkToIcon {
    0% { opacity: 0; transform: scale(0); }
    25% { opacity: 1; transform: scale(1); }
    50% { opacity: 1; transform: scale(1); }
    75% { opacity: 0; transform: scale(0); }
    100% { opacity: 0; transform: scale(0); }
  }
  
  .container {
    position: relative;
    width: 70px;  /* Set a fixed size based on the icon */
    height: 70px; /* Ensure it's the same size as the icons */
    bottom: 70px;
    left: 165px;
  }
  
  .companyIcon {
    animation: iconToCheck 4s infinite;
    font-size: 40px;
    position: relative; /* Overlay this on top of check icon */
    top: 50px;
    left: 50px;
    transform: translate(-50%, -50%); /* Center it perfectly */
  }
  
  .checkIcon {
    animation: checkToIcon 4s infinite;
    font-size: 40px;
    color: white; /* White color for the check */
    background-color: #3669ae; /* Background color */
    border-radius: 50%; /* Make the background round */
    padding: 10px; /* Add padding to create space around the checkmark */
    width: 70px; /* Set width to ensure it's a perfect circle */
    height: 70px; /* Set height for the circle */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute; /* Overlay on top of the company icon */
    top: 65px;
    left: 65px;
    transform: translate(-50%, -50%); /* Center it perfectly */
  }
  